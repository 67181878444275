import * as BABYLON from "@babylonjs/core/Legacy/legacy";

function Global() {

}

Global.camera = "";
Global.alarmPlane = null;
Global.progress = 0;
Global.Hello = "HelloWorld";
Global.BaseUrl = "https://10.32.102.170:11117";
Global.floorList = [];
Global.hiScene = "";
Global.views = {
    AccessControl: {
        target: {
            x: 6.118536802018718,
            y: 80.25453041656425,
            z: 92.23645415788404
        },
        position: {
            x: -584.346090499605,
            y: 170.3094359748245,
            z: 149.97947686751797
        }
    },
    EnergyConsumption: {
        target: {
            x: 6.118536802018718,
            y: 80.25453041656425,
            z: 92.23645415788404
        },
        position: {
            x: -584.346090499605,
            y: 170.3094359748245,
            z: 149.97947686751797
        }
    },
    Broadcast: {
        target: {
            x: -43.52748214917463,
            y: 74.47861577195815,
            z: 142.72647945436424
        },
        position: {
            x: -529.4341537917211,
            y: 215.32613707848867,
            z: 312.03318753594226
        }
    },
    FireControl: {
        target: {
            x: -43.52748214917463,
            y: 74.47861577195815,
            z: 142.72647945436424
        },
        position: {
            x: -529.4341537917211,
            y: 215.32613707848867,
            z: 312.03318753594226
        }
    },
    Illumination: {
        target: {
            x: -10.45052403204808,
            y: 15.169462423501349,
            z: 114.5706777740629
        },
        position: {
            x: -529.8559585008288,
            y: 236.47278513176073,
            z: 5.62164084360613
        }
    },
    Monitor: {
        target: {
            x: -63.683737490505244,
            y: 17.668697954060388,
            z: 127.17284272965827
        },
        position: {
            x: -548.1155477657865,
            y: 276.8105375385947,
            z: 154.70505978494634
        }
    },
    TeachingManagement: {
        target: {
            x: -51.279293870804295,
            y: 38.40395472199863,
            z: 145.89216908442197
        },
        position: {
            x: -508.70699326117864,
            y: 179.68003559024697,
            z: 78.4949049724329
        }
    },
    Msite: {
        target: {
            x: 6.118536802018718,
            y: 80.25453041656425,
            z: 92.23645415788404
        },
        position: {
            x: -584.346090499605,
            y: 170.3094359748245,
            z: 149.97947686751797
        }
    },
    Show: {
        target: {
            x: -120.41951252886872,
            y: -52.294401713592684,
            z: -414.181465162816
        },
        position: {
            x: -1192.647039347035,
            y: 711.8971460627105,
            z: -413.39950473811894
        }
    },
    BuildingAutomation: {
        target: {
            x: -9.767004978229002, y: 35.39218857848845, z: 139.25888506189298
        },
        position: {
            x: -740.8557990038688, y: 581.8076392828162, z: 165.29456896580686
        }
    },
    CO: {
        target: {
            x: -9.767004978229002, y: 35.39218857848845, z: 139.25888506189298
        },
        position: {
            x: -740.8557990038688, y: 581.8076392828162, z: 165.29456896580686
        }
    },
    Water: {
        target: {
            x: -9.767004978229002, y: 35.39218857848845, z: 139.25888506189298
        },
        position: {
            x: -740.8557990038688, y: 581.8076392828162, z: 165.29456896580686
        }
    },
};
Global.overlooking = {
    "中医": {
        target: {
            x: -176.88196127537248, y: 73.16012757308066, z: 68.4197210265455
        },
        position: {
            x: -179.4488001452712, y: 330.0560242158941, z: 68.52615589918094
        }
    },
    "针灸推拿": {
        target: {
            x: -162.99454263747725, y: 73.24327570263021, z: 202.64593601391425
        },
        position: {
            x: -165.56138150737598, y: 330.1391723454437, z: 202.7523708865497
        }
    },
    "中西医结合": {
        target: {
            x: -91.38564602138294, y: 74.06037167252306, z: -42.57552779924694
        },
        position: {
            x: -93.95248489128166, y: 330.9562683153365, z: -42.46909292661151
        }
    },
    "中药": {
        target: {
            x: 3.666348756782023, y: 75.04324219029918, z: -122.55180707017956
        },
        position: {
            x: 1.0995098868833035, y: 331.9391388331126, z: -122.44537219754413
        }
    },
    "图书馆、标本馆、综合教学楼": {
        target: {
            x: 38.79315593968514, y: 75.28990660810163, z: 129.22442149532492
        },
        position: {
            x: 36.226317069786425, y: 332.1858032509151, z: 129.33085636796037
        }
    },
    "行政综合楼": {
        target: {
            x: -305.9418986446712, y: 71.8734892532801, z: 61.43013400411114
        },
        position: {
            x: -308.50873751456993, y: 328.7693858960936, z: 61.53656887674657
        }
    },
    "学术会议中心": {
        target: {
            x: -302.4841548712649, y: 71.84720310001401, z: 208.26429595077516
        },
        position: {
            x: -305.0509937411636, y: 328.74309974282744, z: 208.3707308234106
        }
    },
    "体育馆": {
        target: {
            x: 73.65991770743408, y: 75.50362621736154, z: 454.24630029872924
        },
        position: {
            x: 71.09307883753536, y: 332.399522860175, z: 454.35273517136466
        }
    },
    "食堂": {
        target: {
            x: -51.953958362276204, y: 74.32383224744343, z: 272.479490106105
        },
        position: {
            x: -53.43670923625054, y: 222.72138837553138, z: 272.54097289034684
        }
    },
    "宿舍S1~4": {
        target: {
            x: -95.60773650181592, y: 73.86093478504439, z: 336.9719983890765
        },
        position: {
            x: -98.00805172894727, y: 314.09071302727415, z: 337.0715283005488
        }
    },
    "宿舍S5~7": {
        target: {
            x: -69.78663197100249, y: 74.09422767870197, z: 396.60116739565785
        },
        position: {
            x: -71.84851165721713, y: 280.45249853526366, z: 396.68666395890745
        }
    },
    "全局": {
        target: {
            x: -102.52853530919747, y: 17.282221931191902, z: 121.80270252316896
        },
        position: {
            x: -111.27458587071052, y: 892.2782596991851, z: 122.0738308988792
        }
    }
}

Global.buildingPos = {
    "中医": {
        target: null,
        position: null
    },
    "针灸推拿": {
        target: null,
        position: null
    },
    "中西医结合": {
        target: null,
        position: null
    },
    "中药": {
        target: null,
        position: null
    },
    "图书馆、标本馆、综合教学楼": {
        target: null,
        position: null
    },
    "行政综合楼": {
        target: null,
        position: null
    },
    "学术会议中心": {
        target: null,
        position: null
    },
    "体育馆": {
        target: null,
        position: null
    },
    "食堂": {
        target: null,
        position: null
    },
    "宿舍S1~4": {
        target: null,
        position: null
    },
    "宿舍S5~7": {
        target: null,
        position: null
    },
}
Global.buildingArr = []

Global.Func = {
    "mode": null,
    "rain": null,
    "snow": null,
    "fog": null,
    "animation": null,
    "openSpecialEffects": null,
    "closeSpecialEffects": null,
    "choseBuilding": null,
    "locateModel": function (buildingName, floorNum) {
        switch (buildingName) {
            case "中医":
                buildingName = "一期一号楼";
                break;
            case "针灸推拿":
                buildingName = "一期二号楼";
                break;
            case "中西医结合":
                buildingName = "一期三号楼";
                break;
            case "中药":
                buildingName = "一期四号楼";
                break;
            case "图书馆、标本馆、综合教学楼":
                buildingName = "一期五号楼";
                break;
            case "行政综合楼":
                buildingName = "一期六号楼";
                break;
            case "学术会议中心":
                buildingName = "一期七号楼";
                break;
            case "体育馆":
                buildingName = "一期八号楼";
                break;
            case "食堂":
                buildingName = "一期九号楼";
                break;
            case "宿舍S1~4":
                buildingName = "一期十号楼";
                break;
            case "宿舍S5~7":
                buildingName = "一期十一号楼";
                break;
            default:
                break;
        }
        let hiScene = Global.hiScene;
        // 外壳和玻璃
        let gltf = hiScene.findObjectByName(buildingName);
        let gltfGlass = hiScene.findObjectByName(buildingName + "_" + "玻璃");
        if (!gltf || !gltfGlass) {
            console.log("??????", buildingName, floorNum);
            return;
        }
    },
    "showBuildingInfo": null,
    "cameraMoveTo": function (buildingName) {
        switch (buildingName) {
            case "中医": {
                let newPosition = new BABYLON.Vector3(-340.580855859097, 157.84230837462937, 56.68480733557878);
                let newTarget = new BABYLON.Vector3(5.928771875887554, 14.31332107394636, 56.68480733557874);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "针灸推拿": {
                let newPosition = new BABYLON.Vector3(-335.83406675333885, 169.302070693593, 209.9993188513603);
                let newTarget = new BABYLON.Vector3(10.675560981645745, 25.77308339290998, 209.99931885136027);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "中西医结合": {
                let newPosition = new BABYLON.Vector3(-233.90234207612826, 153.91600706535417, -20.000075187594273);
                let newTarget = new BABYLON.Vector3(20.16332171259468, 48.678563390742156, -20.000075187594305);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "中药": {
                let newPosition = new BABYLON.Vector3(-133.11918022809627, 157.7393725721587, -120.62536261469573);
                let newTarget = new BABYLON.Vector3(36.274438270395024, 87.57423841062959, -120.62536261469575);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "图书馆、标本馆、综合教学楼": {
                let newPosition = new BABYLON.Vector3(-167.6532783992855, 183.19527907072006, 138.46472794215833);
                let newTarget = new BABYLON.Vector3(40.217058830845495, 97.09256617493088, 138.4647279421583);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "行政综合楼": {
                let newPosition = new BABYLON.Vector3(-479.0537098343081, 177.76647398263054, 72.90091489805549);
                let newTarget = new BABYLON.Vector3(-9.875218290392358, -16.78631179540201, 50.94428642271086);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "学术会议中心": {
                let newPosition = new BABYLON.Vector3(-496.43845385431104, 173.52980389419372, 210.30357001190941);
                let newTarget = new BABYLON.Vector3(-11.349678079026683, -27.40054598689039, 210.30357001190936);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "体育馆": {
                let newPosition = new BABYLON.Vector3(-155.63509362612797, 212.26424480538458, 440.2310936630473);
                let newTarget = new BABYLON.Vector3(52.25451292363594, 126.15355029606573, 440.2310936630473);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "食堂": {
                let newPosition = new BABYLON.Vector3(-175.32905161200367, 142.79040125548323, 273.5983048946628);
                let newTarget = new BABYLON.Vector3(24.807685956340347, 59.89105022557021, 273.5983048946628);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "宿舍S1~4": {
                let newPosition = new BABYLON.Vector3(-316.0726164774752, 194.87733550673727, 340.85418297584226);
                let newTarget = new BABYLON.Vector3(22.61178024412429, 54.589665020500924, 340.8541829758422);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
            case "宿舍S5~7": {
                let newPosition = new BABYLON.Vector3(-261.8182494793626, 195.30138216027268, 417.52098065398167);
                let newTarget = new BABYLON.Vector3(30.707071660303313, 74.13342680667799, 417.5209806539816);
                Global.Func.cameraAnimation(newPosition, newTarget);
                break;
            }
        }
    },
    "cameraAnimation": function (position, target) {
        let camera = window.camera;
        let ease = new BABYLON.CubicEase();
        BABYLON.Animation.CreateAndStartAnimation("camMove", camera, "position", 30, 60, camera.position, position, 0, ease);
        let time = setTimeout(function () {
            BABYLON.Animation.CreateAndStartAnimation("camZoom", camera, "target", 30, 30, camera.target, target, 0, ease);
            clearTimeout(time);
        }, 50);
    }
};

Global.warnPoints = [
    {
        type: "入侵报警",
        color: 2,
        position: {x: -198.17773394929173, y: 87.78022019362498, z: 269.02764349731706},
        remark: "非法闯入",
        time: "2022-10-08 12:00"
    },
    {
        type: "入侵报警",
        color: 2,
        position: {x: -85.18998192375764, y: 90.81533120551842, z: 39.336374036077544},
        remark: "非法闯入",
        time: "2022-10-08 12:00"
    }
];

Global.flyTo = function (point) {
    if (!window.camera || !window.scene) {
        return;
    }
    let camera_position = window.camera.position.clone();
    let camera_target = window.camera.target.clone();
    let frameRate = 10;
    let camera_movein = new BABYLON.Animation(
        "camera_movein",
        "position",
        frameRate,
        BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    let camera_targetc = new BABYLON.Animation(
        "camera_targetc",
        "target",
        frameRate,
        BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
    );


    let camera_movein_keys = [];
    camera_movein_keys.push({
        frame: 0,
        value: camera_position
    });
    camera_movein_keys.push({
        frame: 1.5 * frameRate,
        value: new BABYLON.Vector3(point.position.x, point.position.y, point.position.z)
    });

    let camera_target_keys = [];
    camera_target_keys.push({
        frame: 0,
        value: camera_target
    });
    camera_target_keys.push({
        frame: 1.5 * frameRate,
        value: new BABYLON.Vector3(point.target.x, point.target.y, point.target.z)
    });


    camera_movein.setKeys(camera_movein_keys);
    camera_targetc.setKeys(camera_target_keys);
    window.scene.beginDirectAnimation(window.camera, [camera_targetc, camera_movein], 0, 1.5 * frameRate, false);


    function copy(data) {
        let str = JSON.stringify(data);
        return JSON.parse(str);
    }
};
Global.vector3Tovector2 = function (point) {
    return BABYLON.Vector3.Project(point,
        BABYLON.Matrix.Identity(),
        window.scene.getTransformMatrix(),
        window.camera.viewport.toGlobal(window.engine.getRenderWidth(), window.engine.getRenderHeight()));
};

// 场景内所有图标的对象
Global.schoolEquipment = {
    inside: {
        "中医": {},
        "针灸推拿": {},
        "中西医结合": {},
        "中药": {},
        "图书馆、标本馆、综合教学楼": {},
        "行政综合楼": {},
        "学术会议中心": {
            "1楼": {
                101: [
                    {
                        name: "101",
                        type: "manage",
                        position: [-297.4377289103777, 86.62487411499025 + 2, 230.1516044818987],
                        state: false
                    },
                    // {
                    //     name: "监控一",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-297.4377289103777, 86.62487411499025 + 2, 230.1516044818987],
                    //     state: false
                    // },
                    // {
                    //     name: "广播一",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-297.4377289103777, 86.62487411499025 + 2, 230.1516044818987],
                    //     state: false
                    // },
                    {
                        name: "光照一",
                        type: "light",
                        devtype: "室内",
                        position: [-297.4377289103777, 86.62487411499025 + 2, 230.1516044818987],
                        state: false
                    },
                    {
                        name: "发电机01",
                        type: "dynamo",
                        position: [-297.4377289103777, 86.62487411499025 + 2, 230.1516044818987],
                        state: true,
                        location: "学术会议中心1F-101"
                    }
                ],
                102: [
                    {
                        name: "102",
                        type: "manage",
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true
                    },
                    {
                        name: "监控二",
                        type: "monitor",
                        devtype: "球形摄像头",
                        position: [-286.9559837064059, 91.75554346208096, 192.23415353980127],
                        state: true
                    },
                    {
                        name: "广播二",
                        type: "broadcast",
                        devtype: "大喇叭",
                        position: [-305.3212414937014, 91.75489044160409, 191.9473021206122],
                        state: true
                    },
                    {
                        name: "光照二",
                        type: "light",
                        devtype: "室内",
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    },
                    {
                        name: "排风机01",
                        type: "exhaustFan",
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true,
                        location: "学术会议中心1F-102"
                    },
                    {
                        name: "发电机02",
                        type: "dynamo",
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true,
                        location: "学术会议中心1F-102"
                    }
                ],
                103: [
                    {
                        name: "103",
                        type: "manage",
                        position: [-338.1756129584811, 85.9999771118164 + 2, 219.45457333423806],
                        state: true
                    },
                    // {
                    //     name: "监控三",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-338.1756129584811, 85.9999771118164 + 2, 219.45457333423806],
                    //     state: true
                    // },
                    // {
                    //     name: "广播三",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-338.1756129584811, 85.9999771118164 + 2, 219.45457333423806],
                    //     state: true
                    // },
                    {
                        name: "光照三",
                        type: "light",
                        devtype: "室内",
                        position: [-338.1756129584811, 85.9999771118164 + 2, 219.45457333423806],
                        state: true
                    },
                    {
                        name: "空调01",
                        type: "airConditioning",
                        position: [-338.1756129584811, 85.9999771118164 + 2, 219.45457333423806],
                        state: true,
                        location: "学术会议中心1F-103"
                    },
                ],
                104: [
                    {
                        name: "104",
                        type: "manage",
                        position: [-325.2834230580307, 85.99997711181642 + 2, 211.49439958881567],
                        state: false
                    },
                    // {
                    //     name: "监控四",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-325.2834230580307, 85.99997711181642 + 2, 211.49439958881567],
                    //     state: false
                    // },
                    // {
                    //     name: "广播四",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-325.2834230580307, 85.99997711181642 + 2, 211.49439958881567],
                    //     state: false
                    // },
                    {
                        name: "光照四",
                        type: "light",
                        devtype: "室内",
                        position: [-325.2834230580307, 85.99997711181642 + 2, 211.49439958881567],
                        state: false
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    },
                    {
                        name: "电梯01",
                        type: "lift",
                        position: [-329.8777090569438, 86.62487765468194, 231.6826686873067],
                        state: true,
                        location: "学术会议中心1F-104"
                    },
                ],
                105: [
                    {
                        name: "105",
                        type: "manage",
                        position: [-338.11966607008503, 85.99997711181643 + 2, 199.88179976348866],
                        state: true
                    },
                    // {
                    //     name: "监控五",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-338.11966607008503, 85.99997711181643 + 2, 199.88179976348866],
                    //     state: true
                    // },
                    // {
                    //     name: "广播五",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-338.11966607008503, 85.99997711181643 + 2, 199.88179976348866],
                    //     state: true
                    // },
                    {
                        name: "光照五",
                        type: "light",
                        devtype: "室内",
                        position: [-338.11966607008503, 85.99997711181643 + 2, 199.88179976348866],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    },
                    {
                        name: "空调02",
                        type: "airConditioning",
                        position: [-338.11966607008503, 85.99997711181643 + 2, 199.88179976348866],
                        state: true,
                        location: "学术会议中心1F-105"
                    },
                ],
                106: [
                    {
                        name: "106",
                        type: "manage",
                        position: [-334.43824219275325, 85.9999771118164 + 2, 182.9763100025026],
                        state: true
                    },
                    // {
                    //     name: "监控六",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-334.43824219275325, 85.9999771118164 + 2, 182.9763100025026],
                    //     state: true
                    // },
                    // {
                    //     name: "广播六",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-334.43824219275325, 85.9999771118164 + 2, 182.9763100025026],
                    //     state: true
                    // },
                    {
                        name: "光照六",
                        type: "light",
                        devtype: "室内",
                        position: [-334.43824219275325, 85.9999771118164 + 2, 182.9763100025026],
                        state: true
                    },
                    {
                        name: "排风机02",
                        type: "exhaustFan",
                        position: [-334.43824219275325, 85.9999771118164 + 2, 182.9763100025026],
                        state: true,
                        location: "学术会议中心1F-106"
                    },
                ],
                107: [
                    {
                        name: "107",
                        type: "manage",
                        position: [-312.6382376030915, 86.62487391792563 + 2, 184.59825405025623],
                        state: false
                    },
                    {
                        name: "监控七",
                        type: "monitor",
                        devtype: "球形摄像头",
                        position: [-309.00573011114454, 91.75549991640968, 227.60302243131326],
                        state: false
                    },
                    {
                        name: "广播七",
                        type: "broadcast",
                        devtype: "大喇叭",
                        position: [-313.4540206177308, 91.7548904424282, 225.06718403081112],
                        state: false
                    },
                    {
                        name: "光照七",
                        type: "light",
                        devtype: "室内",
                        position: [-312.6382376030915, 86.62487391792563 + 2, 184.59825405025623],
                        state: false
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    },
                    {
                        name: "电梯02",
                        type: "lift",
                        position: [-290.491342563387, 86.62487390715684, 183.28750261636384],
                        state: true,
                        location: "学术会议中心1F-107"
                    },
                ],
                108: [
                    {
                        name: "供水箱01",
                        type: "waterTank",
                        position: [-335.9471135460845, 86.87487529615265, 184.91708287569878],
                        state: true
                    }
                ],
                109: [
                    {
                        name: "配电箱01",
                        type: "powerSupplyBox",
                        position: [-298.21414657250256, 86.62487411499023, 229.99440526771372],
                        state: true
                    }
                ]
            },
            "2楼": {
                201: [
                    {
                        name: "201",
                        type: "manage",
                        position: [-310.10473075637924, 91.87487975263645 + 2, 230.84281735080373],
                        state: false
                    },
                    // {
                    //     name: "监控一",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-310.10473075637924, 91.87487975263645 + 2, 230.84281735080373],
                    //     state: false
                    // },
                    // {
                    //     name: "广播一",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-310.10473075637924, 91.87487975263645 + 2, 230.84281735080373],
                    //     state: false
                    // },
                    {
                        name: "光照一",
                        type: "light",
                        devtype: "室内",
                        position: [-310.10473075637924, 91.87487975263645 + 2, 230.84281735080373],
                        state: false
                    },
                    {
                        name: "空调03",
                        type: "airConditioning",
                        position: [-310.10473075637924, 91.87487975263645 + 2, 230.84281735080373],
                        state: true,
                        location: "学术会议中心1F-105"
                    },

                ],
                202: [
                    {
                        name: "202",
                        type: "manage",
                        position: [-323.6974341664377, 91.87487983703602 + 2, 231.50219686097],
                        state: true
                    },
                    // {
                    //     name: "监控二",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-323.6974341664377, 91.87487983703602 + 2, 231.50219686097],
                    //     state: true
                    // },
                    // {
                    //     name: "广播二",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-323.6974341664377, 91.87487983703602 + 2, 231.50219686097],
                    //     state: true
                    // },
                    {
                        name: "光照二",
                        type: "light",
                        devtype: "室内",
                        position: [-323.6974341664377, 91.87487983703602 + 2, 231.50219686097],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-320.14414048359635, 86.6248771156071 + 2, 231.48184212652993],
                        state: true,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    },
                    {
                        name: "排风机04",
                        type: "exhaustFan",
                        position: [-323.6974341664377, 91.87487983703602 + 2, 231.50219686097],
                        state: true,
                        location: "学术会议中心2F-209"
                    },
                ],
                203: [
                    {
                        name: "203",
                        type: "manage",
                        position: [-336.85708776500076, 91.87487799564084 + 2, 211.4901165081688],
                        state: true
                    },
                    // {
                    //     name: "监控三",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-336.85708776500076, 91.87487799564084 + 2, 211.4901165081688],
                    //     state: true
                    // },
                    // {
                    //     name: "广播三",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-336.85708776500076, 91.87487799564084 + 2, 211.4901165081688],
                    //     state: true
                    // },
                    {
                        name: "光照三",
                        type: "light",
                        devtype: "室内",
                        position: [-336.85708776500076, 91.87487799564084 + 2, 211.4901165081688],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    }

                ],
                204: [
                    {
                        name: "204",
                        type: "manage",
                        position: [-337.3975183092487, 91.87487644674101 + 2, 194.21409273329814],
                        state: false
                    },
                    // {
                    //     name: "监控四",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-337.3975183092487, 91.87487644674101 + 2, 194.21409273329814],
                    //     state: false
                    // },
                    // {
                    //     name: "广播四",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-337.3975183092487, 91.87487644674101 + 2, 194.21409273329814],
                    //     state: false
                    // },
                    {
                        name: "光照四",
                        type: "light",
                        devtype: "室内",
                        position: [-337.3975183092487, 91.87487644674101 + 2, 194.21409273329814],
                        state: false
                    },
                    {
                        name: "空调04",
                        type: "airConditioning",
                        position: [-337.3975183092487, 91.87487644674101 + 2, 194.21409273329814],
                        state: true,
                        location: "学术会议中心1F-105"
                    },
                ],
                205: [
                    {
                        name: "205",
                        type: "manage",
                        position: [-337.40982560364654, 91.87487538800852 + 2, 185.6392240514498],
                        state: true
                    },
                    // {
                    //     name: "监控五",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-337.40982560364654, 91.87487538800852 + 2, 185.6392240514498],
                    //     state: true
                    // },
                    // {
                    //     name: "广播五",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-337.40982560364654, 91.87487538800852 + 2, 185.6392240514498],
                    //     state: true
                    // },
                    {
                        name: "光照五",
                        type: "light",
                        devtype: "室内",
                        position: [-337.40982560364654, 91.87487538800852 + 2, 185.6392240514498],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    },
                    {
                        name: "排风机04",
                        type: "exhaustFan",
                        position: [-337.40982560364654, 91.87487538800852 + 2, 185.6392240514498],
                        state: true,
                        location: "学术会议中心2F-205"
                    },
                ],
                206: [
                    {
                        name: "206",
                        type: "manage",
                        position: [-314.13432492589726, 94.85383224487302 + 2, 184.902606334746],
                        state: true
                    },
                    // {
                    //     name: "监控六",
                    //     type: "monitor",
                    //     devtype: "球形摄像头",
                    //     position: [-314.13432492589726, 94.85383224487302 + 2, 184.902606334746],
                    //     state: true
                    // },
                    // {
                    //     name: "广播六",
                    //     type: "broadcast",
                    //     devtype: "大喇叭",
                    //     position: [-314.13432492589726, 94.85383224487302 + 2, 184.902606334746],
                    //     state: true
                    // },
                    {
                        name: "光照六",
                        type: "light",
                        devtype: "室内",
                        position: [-314.13432492589726, 94.85383224487302 + 2, 184.902606334746],
                        state: true
                    }
                ],
                207: [
                    {
                        name: "供水箱02",
                        type: "waterTank",
                        position: [-336.09413779453934, 91.87487532750214, 185.51308226542946],
                        state: true
                    }
                ],
                208: [
                    {
                        name: "配电箱02",
                        type: "powerSupplyBox",
                        position: [-289.5358587589022, 91.87487999472128, 231.51781400548506],
                        state: true
                    }
                ],
            },
            "3楼": {
                301: [
                    {
                        name: "101",
                        type: "manage",
                        position: [-307.2831314727104, 97.87518758820462, 230.4644421479661],
                        state: false
                    },
                    {
                        name: "监控一",
                        type: "monitor",
                        devtype: "球形摄像头",
                        position: [-307.2831314727104, 97.87518758820462, 230.4644421479661],
                        state: false
                    },
                    {
                        name: "广播一",
                        type: "broadcast",
                        devtype: "大喇叭",
                        position: [-307.2831314727104, 97.87518758820462, 230.4644421479661],
                        state: false
                    },
                    {
                        name: "光照一",
                        type: "light",
                        devtype: "室内",
                        position: [-307.2831314727104, 97.87518758820462, 230.4644421479661],
                        state: false
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    }

                ],
                303: [
                    {
                        name: "103",
                        type: "manage",
                        position: [-336.5686937510585, 97.87518589469147, 211.4009640566694],
                        state: true
                    },
                    {
                        name: "监控三",
                        type: "monitor",
                        devtype: "球形摄像头",
                        position: [-336.5686937510585, 97.87518589469147, 211.4009640566694],
                        state: true
                    },
                    {
                        name: "广播三",
                        type: "broadcast",
                        devtype: "大喇叭",
                        position: [-336.5686937510585, 97.87518589469147, 211.4009640566694],
                        state: true
                    },
                    {
                        name: "光照三",
                        type: "light",
                        devtype: "室内",
                        position: [-336.5686937510585, 97.87518589469147, 211.4009640566694],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    }

                ],
                305: [
                    {
                        name: "105",
                        type: "manage",
                        position: [-317.9216812228876, 97.87518427711035, 192.29735616544048],
                        state: true
                    },
                    {
                        name: "监控五",
                        type: "monitor",
                        devtype: "球形摄像头",
                        position: [-317.9216812228876, 97.87518427711035, 192.29735616544048],
                        state: true
                    },
                    {
                        name: "广播五",
                        type: "broadcast",
                        devtype: "大喇叭",
                        position: [-317.9216812228876, 97.87518427711035, 192.29735616544048],
                        state: true
                    },
                    {
                        name: "光照五",
                        type: "light",
                        devtype: "室内",
                        position: [-317.9216812228876, 97.87518427711035, 192.29735616544048],
                        state: true
                    },
                    {
                        name: "告警",
                        type: "warn",
                        subtype: "入侵报警",
                        color: 0,
                        position: [-297.4377289103777, 86.62487411499025, 230.1516044818987],
                        state: false,
                        remark: "非法闯入",
                        time: "2022-10-08 12:00"
                    }

                ],
                306: [
                    {
                        name: "供水箱03",
                        type: "waterTank",
                        position: [-286.60293764235695, 97.87518319777442, 180.53798049831616],
                        state: true
                    }
                ],
                307: [
                    {
                        name: "配电箱03",
                        type: "powerSupplyBox",
                        position: [-294.28746434760046, 97.8751881211231, 231.474306531943],
                        state: true
                    }
                ]
            }
        },
        "体育馆": {},
        "食堂": {},
        "宿舍S1~4": {},
        "宿舍S5~7": {}
    },
    outside: [
        {
            name: "监控一",
            type: "monitor",
            devtype: "普通摄像头",
            position: [-344.1937634001176 - 5.0, 85.99999999999997, 114.03249950947493],
            state: false
        },
        {
            name: "监控二",
            type: "monitor",
            devtype: "普通摄像头",
            position: [-23.605375442203467, 94.00003051757807, 61.909396004248585],
            state: false
        },
        {
            name: "监控三",
            type: "monitor",
            devtype: "普通摄像头",
            position: [11.031479880638827, 94.128656481517, 406.3891779101706],
            state: false
        },
        {
            name: "广播一",
            type: "broadcast",
            devtype: "小喇叭",
            position: [-237.31516563989334, 87.9812807579836 + 11.0, 40.94255203082727],
            state: true
        },
        {
            name: "广播二",
            type: "broadcast",
            devtype: "小喇叭",
            position: [-91.72444010839826, 88.04670955149157 + 8.0, 259.1836887862655],
            state: true
        },
        {
            name: "广播三",
            type: "broadcast",
            devtype: "小喇叭",
            position: [88.61217167819962, 94 + 13.0, 199.30991722783477],
            state: true
        },
        {
            name: "路灯一",
            type: "light",
            devtype: "路灯",
            position: [-245.33408783804606, 100.32968286617924, 210.05994292750515],
            state: true
        },
        {
            name: "路灯二",
            type: "light",
            devtype: "路灯",
            position: [-154.2051546812475, 100.87435675841655, -7.755282593245678],
            state: true
        },
        {
            name: "路灯三",
            type: "light",
            devtype: "路灯",
            position: [115.56653898300388, 125.62596644237405, 278.85284909560636],
            state: true
        },
        {
            name: "告警一",
            type: "warn",
            subtype: "入侵报警",
            remark: "非法闯入",
            time: "2022-10-08 12:00",
            color: 0,
            position: [-85.18998192375764, 90.81533120551842, 39.336374036077544],
            state: false,
            location: "中西药结合楼旁边"
        },
        {
            name: "告警二",
            type: "warn",
            subtype: "入侵报警",
            remark: "非法闯入",
            time: "2022-10-08 12:00",
            color: 0,
            position: [-198.17773394929173, 87.78022019362498, 269.02764349731706],
            state: false,
            location: "针灸推拿楼旁边"
        },
        {
            name: "CO监测器1",
            type: "coMonitor",
            location: "宿舍S1-4",
            position: [-209.55722874116026, 86.656770982887 + 4.0, 324.624500825481],
            state: true
        },
        {
            name: "CO监测器2",
            type: "coMonitor",
            location: "针灸推拿",
            position: [-236.63605420080165, 87.63958177782138 + 4.0, 160.95625797524957],
            state: true
        },
        {
            name: "CO监测器3",
            type: "coMonitor",
            location: "3号楼负1层停车场",
            position: [-99.01021116608354, 92.68023286597682 + 4.0, -93.73813470781647],
            state: true
        },
        {
            name: "水位监测器1",
            type: "waterMonitor",
            location: "操场旁边",
            position: [134.4966293427674, 94.00158825533015 + 4.0, 320.3549752416864],
            state: true
        },
        {
            name: "水位监测器2",
            type: "waterMonitor",
            location: "二期水坑",
            position: [-278.54023327370817, 87.58707069509342 + 4.0, -176.75036731546095],
            state: true
        },
    ]
};
Global.planeNameArr = [
    "classroom", "monitorPlane", "broadcastPlane", "lightPlane", "useWaterPlane", "useElectricPlane",
    "coPlane", "waterPlane", "fanPlane", "liftPlane", "airPlane", "dynamoPlane"
]

// 创建图标
Global.createIcon = function (iconName, url) {
    let icon
    if (arguments.length === 4) {
        icon = BABYLON.MeshBuilder.CreatePlane(iconName, {height: arguments[2], width: arguments[3]});
    } else {
        icon = BABYLON.MeshBuilder.CreatePlane(iconName, {height: 4, width: 4});
    }
    let materialPlane = new BABYLON.StandardMaterial("Mat", scene);
    materialPlane.diffuseTexture = new BABYLON.Texture(url, scene)
    // 应用透明度
    materialPlane.opacityTexture = new BABYLON.Texture(url, scene);
    materialPlane.backFaceCulling = true;
    materialPlane.ambientColor = new BABYLON.Color3(1, 1, 1);
    materialPlane.disableLighting = true;
    icon.material = materialPlane;
    icon.enablePointerMoveEvents = true;
    icon.setEnabled(false);
    icon.billboardMode = 7;
    return icon;
}
Global.setIcon = function () {
    // 教室空闲/不空闲图标
    let classroomUse = this.createIcon("classroomUse", require("../assets/image/icon/classroom.png"));
    let classroomUnused = this.createIcon("classroomUnused", require("../assets/image/icon/classroom_1.png"));
    // 监控图标
    let monitorPlane = this.createIcon("monitorPlane", require("../assets/image/icon/monitor.png"));
    // 光照开启/关闭图标
    let lightPlaneOn = this.createIcon("lightPlaneOn", require("../assets/image/icon/light_on.png"));
    let lightPlaneOff = this.createIcon("lightPlaneOff", require("../assets/image/icon/light_off.png"));
    // 广播开启/关闭图标
    let broadcastPlaneOn = this.createIcon("broadcastPlaneOn", require("../assets/image/icon/broadcast_on.png"));
    let broadcastPlaneOff = this.createIcon("broadcastPlaneOff", require("../assets/image/icon/broadcast_off.png"));
    // 用水量图标
    let useWaterPlane = this.createIcon("useWaterPlane", require("../assets/image/icon/water_use.png"), 2, 8);
    // 用电量图标
    let useElectricPlane = this.createIcon("useElectricPlane", require("../assets/image/icon/electric_use.png"), 3, 8);
    // co图标
    let coPlane = this.createIcon("coPlane", require("../assets/image/icon/co.png"), 2, 6.8);
    // 集水坑图标
    let waterPlane = this.createIcon("waterPlane", require("../assets/image/icon/water.png"), 2, 6);
    // 排风图标
    let fanPlane = this.createIcon("fanPlane", require("../assets/image/icon/fan.png"));
    // 电梯图标
    let liftPlane = this.createIcon("liftPlane", require("../assets/image/icon/lift.png"));
    // 空调图标
    let airPlane = this.createIcon("airPlane", require("../assets/image/icon/elevator.png"));
    // 发电机图标
    let dynamoPlane = this.createIcon("dynamoPlane", require("../assets/image/icon/electric.png"));


    let cloneIcon = function (equipment, iconPlaneOn, iconPlaneOff, room) {
        let iconPlaneOn_ = iconPlaneOn.clone(iconPlaneOn.name + "_" + room + "_" + equipment.name);
        iconPlaneOn_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1], equipment.position[2]);
        let iconPlaneOff_ = iconPlaneOff.clone(iconPlaneOff.name + "_" + room + "_" + equipment.name);
        iconPlaneOff_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1], equipment.position[2]);
        if (room === "室外") {
            iconPlaneOn_.scaling = new BABYLON.Vector3(4.0, 4.0, 4.0);
            iconPlaneOff_.scaling = new BABYLON.Vector3(4.0, 4.0, 4.0);
            // iconPlaneOn_.position.y += 8.0;
            // iconPlaneOff_.position.y += 8.0;
            Global.outsideIcon.push(iconPlaneOn_, iconPlaneOff_);
        } else {
            Global.insideIcon.push(iconPlaneOn_, iconPlaneOff_);
        }
        equipment.mesh = {
            on: iconPlaneOn_,
            off: iconPlaneOff_
        };
    };

    // 室内图标
    for (let building in Global.schoolEquipment["inside"]) {
        for (let floor in Global.schoolEquipment["inside"][building]) {
            for (let room in Global.schoolEquipment["inside"][building][floor]) {
                let region = Global.schoolEquipment["inside"][building][floor][room];
                region.forEach(equipment => {
                    if (equipment.type === "manage") {
                        // 教学管理图标
                        cloneIcon(equipment, classroomUse, classroomUnused, room);
                    } else if (equipment.type === "broadcast") {
                        // 广播图标
                        cloneIcon(equipment, broadcastPlaneOn, broadcastPlaneOff, room);
                    } else if (equipment.type === "light") {
                        // 光照图标
                        cloneIcon(equipment, lightPlaneOn, lightPlaneOff, room);
                    } else if (equipment.type === "monitor") {
                        // 监控图标
                        let monitorPlane_ = monitorPlane.clone("monitorPlane_" + room + "_" + equipment.name);
                        monitorPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1], equipment.position[2]);
                        equipment.mesh = monitorPlane_;
                        Global.insideIcon.push(monitorPlane_);
                    } else if (equipment.type === "waterTank") {
                        // 用水量图标
                        let useWaterPlane_ = useWaterPlane.clone("useWaterPlane_" + room + "_" + equipment.name);
                        useWaterPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 7.0, equipment.position[2]);
                        equipment.mesh = useWaterPlane_;
                        Global.insideIcon.push(useWaterPlane_);
                    } else if (equipment.type === "powerSupplyBox") {
                        // 用电量图标
                        let useElectricPlane_ = useElectricPlane.clone("useElectricPlane_" + room + "_" + equipment.name);
                        useElectricPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 7.0, equipment.position[2]);
                        equipment.mesh = useElectricPlane_;
                        Global.insideIcon.push(useElectricPlane_);
                    } else if (equipment.type === "exhaustFan") {
                        // 用电量图标
                        let fanPlane_ = fanPlane.clone("fanPlane_" + room + "_" + equipment.name);
                        fanPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 4.0, equipment.position[2]);
                        equipment.mesh = fanPlane_;
                        Global.insideIcon.push(fanPlane_);
                    } else if (equipment.type === "lift") {
                        // 用电量图标
                        let liftPlane_ = liftPlane.clone("liftPlane_" + room + "_" + equipment.name);
                        liftPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 6.0, equipment.position[2]);
                        equipment.mesh = liftPlane_;
                        Global.insideIcon.push(liftPlane_);
                    } else if (equipment.type === "airConditioning") {
                        // 用电量图标
                        let airPlane_ = airPlane.clone("airPlane_" + room + "_" + equipment.name);
                        airPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 4.0, equipment.position[2]);
                        equipment.mesh = airPlane_;
                        Global.insideIcon.push(airPlane_);
                    } else if (equipment.type === "dynamo") {
                        // 用电量图标
                        let dynamoPlane_ = dynamoPlane.clone("dynamoPlane_" + room + "_" + equipment.name);
                        dynamoPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 4.0, equipment.position[2]);
                        equipment.mesh = dynamoPlane_;
                        Global.insideIcon.push(dynamoPlane_);
                    }
                });
            }
        }
    }
    // 室外图标
    Global.schoolEquipment.outside.forEach(equipment => {
        if (equipment.type === "broadcast") {
            // 广播图标
            cloneIcon(equipment, broadcastPlaneOn, broadcastPlaneOff, "室外");
        } else if (equipment.type === "light") {
            // 光照图标
            cloneIcon(equipment, lightPlaneOn, lightPlaneOff, "室外");
        } else if (equipment.type === "monitor") {
            // 监控图标
            let monitorPlane_ = monitorPlane.clone("monitorPlane_室外_" + equipment.name);
            monitorPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1] + 4.0, equipment.position[2]);
            monitorPlane_.position.y += 10.0;
            monitorPlane_.scaling = new BABYLON.Vector3(4.0, 4.0, 4.0);
            equipment.mesh = monitorPlane_;
            Global.outsideIcon.push(monitorPlane_);
        } else if (equipment.type === "coMonitor") {
            // co图标
            let coPlane_ = coPlane.clone("coPlane_室外_" + equipment.name);
            coPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1], equipment.position[2]);
            // coPlane_.position.y += 10.0;
            coPlane_.scaling = new BABYLON.Vector3(4.0, 4.0, 4.0);
            equipment.mesh = coPlane_;
            Global.outsideIcon.push(coPlane_);
        } else if (equipment.type === "waterMonitor") {
            // 集水坑图标
            let waterPlane_ = waterPlane.clone("waterPlane_室外_" + equipment.name);
            waterPlane_.position = new BABYLON.Vector3(equipment.position[0], equipment.position[1], equipment.position[2]);
            waterPlane_.scaling = new BABYLON.Vector3(4.0, 4.0, 4.0);
            equipment.mesh = waterPlane_;
            Global.outsideIcon.push(waterPlane_);
        }

    });
}


Global.findEquipment = function (type) {
    let arr = [];
    //外部
    for (let item of Global.schoolEquipment.outside) {
        if (item.type === type) {
            item.isOutside = true;
            arr.push(item);
        }
    }
    //内部
    for (let build in Global.schoolEquipment.inside) {
        for (let floor in Global.schoolEquipment.inside[build]) {
            for (let room in Global.schoolEquipment.inside[build][floor]) {
                for (let item of Global.schoolEquipment.inside[build][floor][room]) {
                    if (item.type === type) {
                        item.isOutside = false;
                        item.floor = floor;
                        item.room = room;
                        item.build = build;
                        arr.push(item);
                    }
                }

            }
        }
    }
    return arr;
};
Global.choseEquipment = function (data) {
    let point = {
        target: {
            x: data.position[0],
            y: data.position[1],
            z: data.position[2]
        },
        position: {
            x: data.position[0] - 200,
            y: data.position[1] + 90,
            z: data.position[2] + 60
        }
    };
    Global.flyTo(point);
    if (!data.isOutside) {
        let i = data.floor.indexOf("楼");
        let floor = data.floor.slice(0, i);
        Global.Func.locateModel(data.build, floor);
    }
};
Global.insideIcon = [];
Global.outsideIcon = [];
Global.observer = "";

// 光墙效果
Global.setLightWall = function (texture, backColor, lightWall) {
    BABYLON.Effect.ShadersStore["customVertexShader"] = "\r\n" +
        "precision highp float;\r\n" +

        "// Attributes\r\n" +
        "attribute vec3 position;\r\n" +
        "attribute vec2 uv;\r\n" +

        "// Uniforms\r\n" +
        "uniform mat4 worldViewProjection;\r\n" +

        "uniform mat4 projection;\r\n" +
        "uniform mat4 view;\r\n" +
        "uniform mat4 world;\r\n" +
        "uniform float time;\r\n" +
        "uniform float modelHeight;\r\n" +

        "// Varying\r\n" +
        "varying vec2 vUV;\r\n" +
        "varying vec3 vPosition;\r\n" +
        "varying float vAlpha;\r\n" +

        "void main(void) {\r\n" +
        "vec4 modelPosition = world * vec4(position, 1.0);\r\n" +
        "vec4 viewPosition = view * modelPosition;\r\n" +
        "vec4 projectedPosition = projection * viewPosition;\r\n" +
        "gl_Position = projectedPosition;\r\n" +
        "vAlpha = uv.t;\r\n" +
        "vPosition = position;\r\n" +
        "vUV = vec2(position.x, position.y /  10.0 - time);\r\n" +
        "//vUV = uv;\r\n" +
        "}\r\n";

    BABYLON.Effect.ShadersStore["customFragmentShader"] = "\r\n" +
        "precision highp float;\r\n" +

        "varying vec2 vUV;\r\n" +
        "varying vec3 vPosition;\r\n" +
        "varying float vAlpha;\r\n" +

        "uniform sampler2D textureSampler;\r\n" +
        "//uniform samplerCube textureSampler;\r\n" +
        "uniform float time;\r\n" +
        "uniform float modelHeight;\r\n" +
        "uniform float modelMin;\r\n" +
        "uniform vec4 backColor;\r\n" +

        "void main(void) {\r\n" +
        "//vec2 uv = vec2(vUV.x, vUV.y * 5.0 + time);\r\n" +
        "vec4 uvColor = texture2D(textureSampler, vUV);\r\n" +
        "//uvColor.a = 0.5;\r\n" +
        "gl_FragColor = mix(backColor, uvColor, 0.5);\r\n" +
        "//gl_FragColor = backColor;\r\n" +
        "gl_FragColor.a = vAlpha;\r\n" +
        "//gl_FragColor.a = 1.0 - (vPosition.y) / (modelHeight);\r\n" +
        "//if( vPosition.x > vUV.s ) gl_FragColor = vec4(0.0, 1.0, 0.0, 1.0);\r\n" +
        "//else gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);\r\n" +
        "}\r\n";

    let shaderMaterial = new BABYLON.ShaderMaterial("shader", scene, {
        vertex: "custom",
        fragment: "custom"
    }, {
        attributes: ["position", "normal", "uv"],
        uniforms: ["world", "worldView", "worldViewProjection", "view", "projection", "time", "backColor", "modelHeight", "modelMin"]
    });
    shaderMaterial.alpha = 0.0;

    let mainTexture = new BABYLON.Texture(texture, scene);
    // mainTexture.hasAlpha = true
    shaderMaterial.setTexture("textureSampler", mainTexture);

    // shaderMaterial.diffuseTexture = new BABYLON.Texture("http://files.haijigis.com:28081/model/model/redlight.png", scene);
    shaderMaterial.backFaceCulling = false;
    let bounds = lightWall.meshes[0].getHierarchyBoundingVectors();
    let modelHeight = bounds.max.y - bounds.min.y;
    let modelMin = bounds.min.y;

    shaderMaterial.setVector4("backColor", backColor);
    shaderMaterial.setFloat("modelHeight", modelHeight);
    shaderMaterial.setFloat("modelMin", modelMin);

    let time = 0;
    scene.onBeforeRenderObservable.add(() => {
        time += scene.getEngine().getDeltaTime() * 0.002;
        shaderMaterial.setFloat("time", time);
    });
    return shaderMaterial;
};
// 扩散圆效果
Global.setSpreadCircle = function () {
    BABYLON.Effect.ShadersStore["circleVertexShader"] = "\r\n" +
        "precision highp float;\r\n" +

        "// Attributes\r\n" +
        "attribute vec3 position;\r\n" +
        "attribute vec2 uv;\r\n" +

        "// Uniforms\r\n" +
        "uniform mat4 worldViewProjection;\r\n" +
        "uniform mat4 projection;\r\n" +
        "uniform mat4 view;\r\n" +
        "uniform mat4 world;\r\n" +
        "uniform float time;\r\n" +

        "// Varying\r\n" +
        "varying vec2 vUV;\r\n" +
        "varying vec3 vPosition;\r\n" +

        "void main(void) {\r\n" +
        "vec4 modelPosition = world * vec4(position, 1.0);\r\n" +
        "vec4 viewPosition = view * modelPosition;\r\n" +
        "vec4 projectedPosition = projection * viewPosition;\r\n" +
        "gl_Position = projectedPosition;\r\n" +
        "vUV = vec2(uv.x, uv.y);\r\n" +
        "vPosition = position;\r\n" +
        "}\r\n";

    BABYLON.Effect.ShadersStore["circleFragmentShader"] = "\r\n" +
        "precision highp float;\r\n" +

        "varying vec2 vUV;\r\n" +

        "//uniform sampler2D textureSampler;\r\n" +
        "uniform float uRadius; // 设置一个半径范围\r\n" +
        "//uniform float time; \r\n" +
        "uniform vec3 uColor;\r\n" +
        "uniform vec3 uColor1;\r\n" +
        "varying vec3 vPosition;\r\n" +

        "void main(void) {\r\n" +
        "vec3 vCenter = vec3(.0, .0, .0);\r\n" +
        " float len = distance(vCenter, vPosition);\r\n" +
        "if (len < uRadius) {\r\n" +
        "gl_FragColor = vec4(uColor, 1.0);\r\n" +
        "//gl_FragColor = texture2D(textureSampler, vUV);\r\n" +
        "gl_FragColor.a = pow(len / uRadius, 3.0);\r\n" +
        "}\r\n" +
        " else {\r\n" +
        "gl_FragColor = vec4(uColor1, 0.0);\r\n" +
        "}\r\n" +
        "}\r\n";


    let shaderMaterial = new BABYLON.ShaderMaterial("shader", scene, {
            vertex: "circle",
            fragment: "circle"
        },
        {
            attributes: ["position", "normal", "uv"],
            uniforms: ["world", "worldView", "worldViewProjection", "view", "time",
                "projection", "uColor", "uColor1", "uRadius"]
        });


    // let mainTexture = new BABYLON.Texture(require("../assets/image/bg/circle.png"), scene);
    //
    // shaderMaterial.setTexture("textureSampler", mainTexture);
    shaderMaterial.setVector3("uColor", new BABYLON.Vector3(1, 0, 0));
    shaderMaterial.setVector3("uColor1", new BABYLON.Vector3(0, 1, 0));
    let uRadius = 0.0;
    shaderMaterial.setFloat("uRadius", uRadius);

    shaderMaterial.alpha = 0.0;
    shaderMaterial.backFaceCulling = false;

    let time = 0;
    scene.onBeforeRenderObservable.add(() => {
        time += scene.getEngine().getDeltaTime() * 0.002;
        shaderMaterial.setFloat("time", time);
        uRadius += 0.2;
        if (uRadius > 30.0) {
            uRadius = 0.0;
        }
        shaderMaterial.setFloat("uRadius", uRadius);
    });
    return shaderMaterial;
};

// 雨粒子系统
Global.myRainParticleSystem = function () {
    let particleSystem = new BABYLON.ParticleSystem("particles", 20000, scene);
    //Texture of each particle  flare.png snow
    particleSystem.particleTexture = new BABYLON.Texture(require("../assets/image/icon/raining.png"), scene);

    // Where the particles come from
    //  particleSystem.emitter = fountain; // the starting object, the emitter
    particleSystem.minEmitBox = new BABYLON.Vector3(1000, 500, 1000); // Starting all from
    particleSystem.maxEmitBox = new BABYLON.Vector3(-1000, 300, -1000); // To...

    // Colors of all particles
    particleSystem.color1 = new BABYLON.Color4(0.7, 0.8, 1.0, 0.5);
    particleSystem.color2 = new BABYLON.Color4(0.7, 0.8, 1.0, 0.5);//BABYLON.Color4(0.2, 0.5, 1.0, 0.5);
    particleSystem.colorDead = new BABYLON.Color4(0.7, 0.8, 1.0, 0.5);//BABYLON.Color4(0, 0, 0.2, 0.0);

    // Size of each particle (random between...
    particleSystem.minSize = 2;
    particleSystem.maxSize = 2;
    // particleSystem.minScaleX = 0.25;
    // particleSystem.maxScaleX = 0.25;
    particleSystem.minScaleY = 3;
    particleSystem.maxScaleY = 3;

    // Life time of each particle (random between...
    particleSystem.minLifeTime = 2;
    particleSystem.maxLifeTime = 2;

    // Emission rate
    particleSystem.emitRate = 10000; //rain/snow density

    particleSystem.isAnimationSheetEnabled = true;
    particleSystem.spriteCellHeight = 512;
    particleSystem.spriteCellWidth = 128;
    particleSystem.spriteRandomStartCell = true;
    particleSystem.startSpriteCellID = 0;
    particleSystem.endSpriteCellID = 3;
    particleSystem.spriteCellChangeSpeed = 0;


    // Blend mode : BLENDMODE_ONEONE, or BLENDMODE_STANDARD
    particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;

    // Set the gravity of all particles
    particleSystem.gravity = new BABYLON.Vector3(0, -9.81, 0);

    // Direction of each particle after it has been emitted
    particleSystem.direction1 = new BABYLON.Vector3(0, -100, 0);
    particleSystem.direction2 = new BABYLON.Vector3(0, -100, 0);

    // Angular speed, in radians
    particleSystem.minAngularSpeed = 0;
    particleSystem.maxAngularSpeed = 0;//Math.PI;

    // Speed
    particleSystem.minEmitPower = 2;
    particleSystem.maxEmitPower = 2;//3
    particleSystem.updateSpeed = 0.01;
    particleSystem.stop();
    return particleSystem;
};
// 雪粒子系统
Global.mySnowParticleSystem = function () {
    let particleSystem = new BABYLON.ParticleSystem("particles", 20000, scene);
    //Texture of each particle  flare.png snow"http://files.haijigis.com:28081/model/model/snow.png"
    particleSystem.particleTexture = new BABYLON.Texture(require("../assets/image/icon/snowing.png"), scene);

    // Pre-warm
    // particleSystem.preWarmStepOffset = 10;
    // particleSystem.preWarmCycles = 10;

    // Where the particles come from
    // particleSystem.emitter = new BABYLON.Vector3(0, -300, 0); // the starting object, the emitter
    particleSystem.minEmitBox = new BABYLON.Vector3(1000, 500, 1000); // Starting all from
    particleSystem.maxEmitBox = new BABYLON.Vector3(-1000, 0, -1000); // To...

    // Colors of all particles
    particleSystem.color1 = new BABYLON.Color4(1.0, 1.0, 1.0, 1.0);
    particleSystem.color2 = new BABYLON.Color4(1.0, 1.0, 1.0, 1.0);//BABYLON.Color4(0.2, 0.5, 1.0, 1.0);
    particleSystem.colorDead = new BABYLON.Color4(1.0, 1.0, 1.0, 1.0);//BABYLON.Color4(0, 0, 0.2, 0.0);

    // Size of each particle (random between...
    particleSystem.minSize = 3;
    particleSystem.maxSize = 6;

    // Life time of each particle (random between...
    particleSystem.minLifeTime = 5.0;
    particleSystem.maxLifeTime = 10.0;

    // Emission rate
    particleSystem.emitRate = 10000; //rain/snow density

    particleSystem.isAnimationSheetEnabled = true;
    particleSystem.spriteCellHeight = 32;
    particleSystem.spriteCellWidth = 32;
    particleSystem.spriteRandomStartCell = true;
    particleSystem.startSpriteCellID = 0;
    particleSystem.endSpriteCellID = 3;
    particleSystem.spriteCellChangeSpeed = 0;

    // Blend mode : BLENDMODE_ONEONE, or BLENDMODE_STANDARD
    particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_STANDARD;

    // Set the gravity of all particles
    particleSystem.gravity = new BABYLON.Vector3(0, -9.81, 0);

    // Direction of each particle after it has been emitted
    particleSystem.direction1 = new BABYLON.Vector3(30, -30, -30);
    particleSystem.direction2 = new BABYLON.Vector3(-30, -30, 30);

    // Angular speed, in radians
    particleSystem.minAngularSpeed = 0;
    particleSystem.maxAngularSpeed = Math.PI;//Math.PI;

    // Speed
    particleSystem.minEmitPower = 5;
    particleSystem.maxEmitPower = 10;//3
    particleSystem.updateSpeed = 0.001;
    particleSystem.stop();
    return particleSystem;
};

// 元素拖拽和缩放
Global.DragAndZoom = class {
    constructor(id, vector2, options = {limit: true, drag: true, zoom: true, minWidth: 0, minHeight: 0}) {
        this.target = document.getElementById(id)
        this.options = options
        this.vector2 = vector2
        if (id !== "video_dialog") {
            this.options.zoom = false
        }
        this.init()
    }

    // 初始化
    init() {
        this.getBoundary()
        this.target.style.transform = `translate(${this.vector2.x}px, ${this.vector2.y}px)`

        if (this.options.drag) {
            this.drag()
        }
        if (this.options.zoom) {
            this.addHorn()
            this.addBorder()
            this.leftZoom()
            this.rightZoom()
            this.topZoom()
            this.bottomZoom()
            this.leftTopZoom()
            this.leftBottomZoom()
            this.rightTopZoom()
            this.rightBottomZoom()
        }
    }

    // 获取父元素的宽高
    getBoundary() {
        // this.maxWidth = this.target.parentNode.clientWidth
        // this.maxHeight = this.target.parentNode.clientHeight
        this.maxWidth = document.body.clientWidth
        this.maxHeight = document.body.clientHeight
    }

    // 获取自身起始信息
    getInfo(e) {
        this.width = this.target.clientWidth
        this.height = this.target.clientHeight
        let translateStr = this.target.style.transform
        if (translateStr) {
            const reg = /\d+(\.\d+)?/g
            let translateArr = translateStr.match(reg)
            this.tx = Number(translateArr[0])
            this.ty = Number(translateArr[1])
        } else {
            this.tx = 0
            this.ty = 0
        }
        this.startX = e.clientX
        this.startY = e.clientY
    }

    // 拖动实现
    drag() {
        this.target.addEventListener("mousedown", (e) => {
            this.getInfo(e)
            document.onmousemove = (e) => {
                this.target.style.cursor = "move"
                if (this.options.limit) {
                    this.distanceX = Math.max(0, Math.min(this.tx + e.clientX - this.startX, this.maxWidth - this.width))
                    this.distanceY = Math.max(0, Math.min(this.ty + e.clientY - this.startY, this.maxHeight - this.height))
                } else {
                    this.distanceX = this.tx + e.clientX - this.startX
                    this.distanceY = this.ty + e.clientY - this.startY
                }
                this.target.style.transform = `translate(${this.distanceX}px, ${this.distanceY}px)`
            }
            document.onmouseup = () => {
                this.target.style.cursor = "default"
                document.onmousemove = null
            }
        })
    }

    // 添加四个角
    addHorn() {
        this.leftTop = document.createElement("div")
        this.rightTop = document.createElement("div")
        this.leftBottom = document.createElement("div")
        this.rightBottom = document.createElement("div")
        this.leftTop.className = "horn leftTop"
        this.rightTop.className = "horn rightTop"
        this.leftBottom.className = "horn leftBottom"
        this.rightBottom.className = "horn rightBottom"
        this.target.append(this.leftTop)
        this.target.append(this.rightTop)
        this.target.append(this.leftBottom)
        this.target.append(this.rightBottom)
    }

    // 添加四条边
    addBorder() {
        this.left = document.createElement("div")
        this.right = document.createElement("div")
        this._top = document.createElement("div")
        this.bottom = document.createElement("div")
        this.left.className = "vertical v_left"
        this.right.className = "vertical v_right"
        this._top.className = "horizontal v_top"
        this.bottom.className = "horizontal v_bottom"
        this.target.append(this.left)
        this.target.append(this.right)
        this.target.append(this._top)
        this.target.append(this.bottom)
    }

    // 缩放实现
    zoom(el, direction) {
        el.addEventListener("mousedown", (e) => {
            e.stopPropagation()
            this.getInfo(e)
            document.onmousemove = (e) => {
                switch (direction) {
                    case "left":
                        this.leftInfo(e);
                        break;
                    case "right":
                        this.rightInfo(e);
                        break;
                    case "top":
                        this.topInfo(e);
                        break;
                    case "bottom":
                        this.bottomInfo(e);
                        break;
                    case "leftTop":
                        this.leftTopInfo(e);
                        break;
                    case "leftBottom":
                        this.leftBottomInfo(e);
                        break;
                    case "rightTop":
                        this.rightTopInfo(e);
                        break;
                    case "rightBottom":
                        this.rightBottomInfo(e);
                        break;
                }
                // 这里不能直接使用对this.newWidth隐式类型转换来判断，因为this.newWidth===0时，会使用this.width
                let width = this.newWidth !== undefined ? this.newWidth : this.width
                let height = this.newHeight !== undefined ? this.newHeight : this.height
                let translateX = this.distanceX !== undefined ? this.distanceX : this.tx
                let translateY = this.distanceY !== undefined ? this.distanceY : this.ty
                this.target.style.width = `${width}px`
                this.target.style.height = `${height}px`
                this.target.style.transform = `translate(${translateX}px, ${translateY}px)`
            }
            document.onmouseup = () => {
                document.onmousemove = null
            }
        })
    }

    // 获取缩放时宽高、translate等参数的值
    leftInfo(e) {
        this.newWidth = this.width - (e.clientX - this.startX)
        this.distanceX = this.tx + (e.clientX - this.startX)
        if (this.options.limit) {
            this.newWidth = Math.max(this.options.minWidth, Math.min(this.newWidth, this.width + this.tx))
            this.distanceX = Math.max(0, Math.min(this.distanceX, this.width + this.tx - this.options.minWidth))
        }
    }

    rightInfo(e) {
        this.newWidth = this.width + (e.clientX - this.startX)
        if (this.options.limit) {
            this.newWidth = Math.max(this.options.minWidth, Math.min(this.newWidth, this.maxWidth - this.tx))
        }
    }

    topInfo(e) {
        this.newHeight = this.height - (e.clientY - this.startY)
        this.distanceY = this.ty + (e.clientY - this.startY)
        if (this.options.limit) {
            this.newHeight = Math.max(this.options.minHeight, Math.min(this.newHeight, this.height + this.ty))
            this.distanceY = Math.max(0, Math.min(this.distanceY, this.height + this.ty - this.options.minHeight))
        }
    }

    bottomInfo(e) {
        this.newHeight = this.height + (e.clientY - this.startY)
        if (this.options.limit) {
            this.newHeight = Math.max(this.options.minHeight, Math.min(this.newHeight, this.maxHeight - this.ty))
        }
    }

    leftTopInfo(e) {
        this.leftInfo(e)
        this.topInfo(e)
    }

    leftBottomInfo(e) {
        this.leftInfo(e)
        this.bottomInfo(e)
    }

    rightTopInfo(e) {
        this.rightInfo(e)
        this.topInfo(e)
    }

    rightBottomInfo(e) {
        this.rightInfo(e)
        this.bottomInfo(e)
    }

    leftZoom() {
        this.zoom(this.left, "left")
    }

    rightZoom() {
        this.zoom(this.right, "right")
    }

    topZoom() {
        this.zoom(this._top, "top")
    }

    bottomZoom() {
        this.zoom(this.bottom, "bottom")
    }

    leftTopZoom() {
        this.zoom(this.leftTop, "leftTop")
    }

    leftBottomZoom() {
        this.zoom(this.leftBottom, "leftBottom")
    }

    rightTopZoom() {
        this.zoom(this.rightTop, "rightTop")
    }

    rightBottomZoom() {
        this.zoom(this.rightBottom, "rightBottom")
    }
}
export default Global;
