import Global from "./global";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import flvjs from "flv.js";

let flvPlayer

function Message() {

}

Message.changeNav = function () {

}
Message.closeSpecialEffect = function () {

}

let hiScene = Global.hiScene;
Message.floorListDefault = [
    {value: 1, label: "1"},
    {value: 2, label: "2"},
    {value: 3, label: "3"},
    {value: 4, label: "4"},
    {value: 5, label: "5"},
    {value: 6, label: "6"},
    {value: 7, label: "7"},
    {value: 8, label: "8"},
    {value: 9, label: "9"}
]

// 选中点击的楼层
Message.choseBuilding = function (buildingName) {
    // 数据重置
    this.choseIndex = ''
    let gltf = null;
    let gltfGlass = null;
    let oldFloorArr = this.floorArr
    this.floorArr = null
    this.isLight = false
    this.isBroadcast = false
    this.isManage = false

    this.buildingName = buildingName
    switch (buildingName) {
        case "中医":
            buildingName = "一期一号楼"
            break
        case "针灸推拿":
            buildingName = "一期二号楼"
            break
        case "中西医结合":
            buildingName = "一期三号楼"
            break
        case "中药":
            buildingName = "一期四号楼"
            break
        case "图书馆、标本馆、综合教学楼":
            buildingName = "一期五号楼"
            break
        case "行政综合楼":
            buildingName = "一期六号楼"
            break
        case "学术会议中心":
            buildingName = "一期七号楼"
            break
        case "体育馆":
            buildingName = "一期八号楼"
            break
        case "食堂":
            buildingName = "一期九号楼"
            break
        case "宿舍S1~4":
            buildingName = "一期十号楼"
            break
        case "宿舍S5~7":
            buildingName = "一期十一号楼"
            break
        default:
            break
    }

    // 上一栋教学楼的外壳出现动画
    if (this.gltfArr.length !== 0 && this.isShellGone) {
        this.appearAnimation(this.gltfArr);
        this.isShellGone = false
    }
    // 上一栋教学楼隐藏的楼层出现动画
    if (this.hiddenFloor !== 0) {
        this.appearAnimation(this.hiddenFloor);
        this.displayFloor = [];
        this.appearFloor = [];
        this.hiddenFloor = [];
    }
    // 上一栋教学楼的楼层隐藏
    if (oldFloorArr.length !== 0) {
        let time = setTimeout(() => {
            // 重复点击一栋楼的情况
            if (oldFloorArr[0].name !== this.floorArr[0].name) {
                oldFloorArr.forEach(item => item.bNode.setEnabled(false));
                oldFloorArr = null;
            }
            clearTimeout(time);
        }, 1000);
    }

    // 外壳和玻璃
    this.gltfArr = [];
    gltf = hiScene.findObjectByName(buildingName);
    gltfGlass = hiScene.findObjectByName(buildingName + "_" + "玻璃");
    if (!gltf || !gltfGlass) {
        return;
    }
    this.gltfArr.push(gltf, gltfGlass);
    // 1、相机移动
    Global.Func.cameraMoveTo(buildingName);
    this.floorArr = hiScene.objects.filter(item => item.name.match(buildingName) && item.name.match("F"));
    this.floorArr.forEach(item => item.bNode.setEnabled(true));

    // 2、显示的楼层
    this.floorList = this.floorListDefault.slice(0, this.floorArr.length);
}

// 模型消失动画
Message.vanishingAnimation = function (gltfArr) {
    // 建筑外壳和玻璃消失动画
    const frameRate = 10;
    const xSlide = new BABYLON.Animation("xSlide", "position.y", frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
    const keyFrames = [];

    keyFrames.push({
        frame: 0,
        value: 0
    });

    keyFrames.push({
        frame: frameRate,
        value: 200
    });

    xSlide.setKeys(keyFrames);

    gltfArr.forEach(gltf => {
        gltf.bNode.animations.push(xSlide);
        scene.beginAnimation(gltf.bNode, 0, frameRate, false);
    });
    let time = setTimeout(function () {
        gltfArr.forEach(gltf => gltf.bNode.setEnabled(false));
        clearTimeout(time);
    }, 500);

}
// 模型恢复动画
Message.appearAnimation = function (gltfArr) {
    gltfArr.forEach(gltf => {
        gltf.bNode.setEnabled(true);
    });
    // 建筑外壳和玻璃出现动画
    const frameRate = 10;
    const xSlide = new BABYLON.Animation("xSlide", "position.y", frameRate, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
    const keyFrames = [];

    keyFrames.push({
        frame: 0,
        value: 200
    });

    keyFrames.push({
        frame: frameRate,
        value: 0
    });

    xSlide.setKeys(keyFrames);

    gltfArr.forEach(gltf => {
        gltf.bNode.animations.push(xSlide);
        scene.beginAnimation(gltf.bNode, 0, frameRate, false);
    });
}

Message.observer = null
Message.offsetX = ''
// 动态调整弹窗的位置
Message.setPopPosition = function (id, chosePoint) {
    if (Message.observer) {
        scene.onBeforeRenderObservable.remove(Message.observer)
    }
    Message.observer = scene.onBeforeRenderObservable.add(() => {
        let vector2 = Global.vector3Tovector2(chosePoint);
        if (vector2.x === Message.offsetX) {
            return
        }
        // console.log("chosePoint", chosePoint)
        // console.log("vector2", vector2)
        Message.offsetX = vector2.x
        let ele = document.getElementById(id)

            let moveX = vector2.x;
            let moveY = vector2.y;
            if ((moveX + ele.offsetWidth) > document.documentElement.clientWidth) {
                moveX = document.documentElement.clientWidth - ele.offsetWidth;
            }
            if ((moveY + ele.offsetHeight) > document.documentElement.clientHeight) {
                moveY = document.documentElement.clientHeight - ele.offsetHeight;
            }
        ele.style.transform = `translate(${moveX}px, ${moveY}px)`

        // if (ele.id === "video_dialog_icon") {
        //     ele.style.transform = `translate(${vector2.x}px, ${vector2.y}px)`
        // } else {
        //     let moveX = vector2.x;
        //     let moveY = vector2.y;
        //     if ((moveX + ele.offsetWidth) > document.documentElement.clientWidth) {
        //         moveX = document.documentElement.clientWidth - ele.offsetWidth;
        //     }
        //     if ((moveY + ele.offsetHeight) > document.documentElement.clientHeight) {
        //         moveY = document.documentElement.clientHeight - ele.offsetHeight;
        //     }
        //     ele.style.left = moveX + "px";
        //     ele.style.top = moveY + "px";
        // }
    })
}

// 监控视频
Message.deleteVideo = function () {
    if (flvPlayer) {
        flvPlayer.pause();
        flvPlayer.unload();
        flvPlayer.detachMediaElement();
        flvPlayer.destroy();
        flvPlayer = null;
    }
}
Message.startVideo = function (data, videoID) {
    let that = this;
    that.loading = true;
    let videoElement = document.getElementById(videoID);
    flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: data.url
    });
    flvPlayer.attachMediaElement(videoElement);
    flvPlayer.load();
    flvPlayer.play().then(() => {
        that.loading = false;
    });
}

export default Message
